import { render } from "./DataTable.vue?vue&type=template&id=32f55450"
import script from "./DataTable.vue?vue&type=script&lang=js"
export * from "./DataTable.vue?vue&type=script&lang=js"

import "./DataTable.vue?vue&type=style&index=0&id=32f55450&lang=less"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "32f55450"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('32f55450', script)) {
    api.reload('32f55450', script)
  }
  
  module.hot.accept("./DataTable.vue?vue&type=template&id=32f55450", () => {
    api.rerender('32f55450', render)
  })

}

script.__file = "src/views/setting/searchBasicSetting/DataTable.vue"

export default script
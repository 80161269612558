import { render } from "./SearchBlack.vue?vue&type=template&id=59e3c24b"
import script from "./SearchBlack.vue?vue&type=script&lang=js"
export * from "./SearchBlack.vue?vue&type=script&lang=js"

import "./SearchBlack.vue?vue&type=style&index=0&id=59e3c24b&lang=less"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "59e3c24b"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('59e3c24b', script)) {
    api.reload('59e3c24b', script)
  }
  
  module.hot.accept("./SearchBlack.vue?vue&type=template&id=59e3c24b", () => {
    api.rerender('59e3c24b', render)
  })

}

script.__file = "src/views/setting/searchBasicSetting/SearchBlack.vue"

export default script
<template>
  <div class="search-box">
    <a-row>
      <a-col :sm="24" :md="4">查詢條件</a-col>
      <a-col :sm="24" :md="20">
        <a-form-item label="健康項目" style="margin-top: 20px">
          <a-select
            v-model:value="searchData.itemType"
            style="width: 120px; margin-left: 20px"
          >
            <a-select-option value="">請選擇</a-select-option>
            <a-select-option
              v-for="item in measureType"
              :key="item.value"
              :value="item.value"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="標準值名稱" style="margin-top: 20px">
          <a-input v-model:value="searchData.searchString" />
        </a-form-item>
        <a-button type="primary" @click="handleSearch">查詢</a-button>
      </a-col>
    </a-row>
  </div>
</template>

<script>
  const measureType = [
    { label: '血壓', value: 'bloodPressure' },
    { label: '身體組成', value: 'weight' },
    { label: '血氧', value: 'SpO2' },
    { label: '體溫', value: 'bodyTemperature' },
    { label: '血糖', value: 'bloodGlucose' },
  ]
  import { computed } from 'vue'
  import { useStore } from 'vuex'
  export default {
    setup(props, { emit }) {
      const store = useStore()
      const searchData = computed(() => store.getters['searchSetting/search'])

      const handleSearch = () => {
        emit('search')
      }
      return {
        searchData,
        handleSearch,
        measureType,
      }
    },
  }
</script>

<style lang="less">
  .search-box {
    background-color: #fff;
    border: 0.5px solid #000000;
    box-sizing: border-box;
    padding: 20px;

    .ant-form-item {
      label {
        margin-right: 20px;
      }
      input {
        width: 300px;
      }
    }

    button {
      margin: 10px;
    }
  }
</style>

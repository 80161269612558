<template>
  <div class="searchBasicSetting-container">
    <a-page-header title="健康項目標準值設定">
      <template #tags>
        <QuestionCircleOutlined />
      </template>
    </a-page-header>
    <SearchBlack :filter="filter" @search="handleSearch"></SearchBlack>
    <a-button type="primary" @click="handleAddBasciSetting">
      新增標準值
    </a-button>
    <DataTable
      typeString="basicSetting"
      @edit="handleEdit"
      @delete="handleDelete"
    />
  </div>
</template>

<script>
  import { reactive, computed } from 'vue'
  import { useStore } from 'vuex'
  import { useRouter } from 'vue-router'
  import { QuestionCircleOutlined } from '@ant-design/icons-vue'

  import SearchBlack from './SearchBlack'
  import DataTable from './DataTable'
  export default {
    name: 'accountManagement',
    components: {
      QuestionCircleOutlined,
      SearchBlack,
      DataTable,
    },
    setup() {
      const store = useStore()
      const router = useRouter()
      const filter = reactive({})
      const searchData = computed(() => store.getters['searchSetting/search'])

      const handleSearch = () => {
        store.dispatch('searchSetting/getSettingList', {
          type: 'basic',
          searchString: searchData.value.searchString,
          itemType: searchData.value.itemType,
        })
      }

      const handleAddBasciSetting = () => {
        router.push({
          path: `searchSetting/basic/-1`,
        })
      }
      const handleEdit = (id) => {
        router.push({
          path: `searchSetting/basic/${id}`,
        })
      }

      const handleDelete = (id) => {
        store.dispatch('searchSetting/removeSettingList', { id, type: 'basic' })
      }

      return {
        filter,
        handleSearch,
        handleAddBasciSetting,
        handleEdit,
        handleDelete,
      }
    },
  }
</script>
<style lang="less">
  .searchBasicSetting-container {
  }
</style>
